// import { Link } from "gatsby"
import React from "react"

import "./flavorsMenu.scss"

import caramel from "../assets/images/product/bottles/no glow/bottle-dunkin-caramel-extra-extra-32oz.png"
import extraExtra from "../assets/images/product/bottles/no glow/bottle-dunkin-extra-extra-32oz _ 48oz.png"
import original from "../assets/images/product/bottles/no glow/bottle-dunkin-original-32oz.png"
import vanilla from "../assets/images/product/bottles/no glow/bottle-dunkin-vanilla-extra-extra-32oz.png"

const FlavorsMenu = () => {
	return (
		<>
			<div id='flavors-menu'>
				<h2>
					<span className='orange'>SWEETEN YOUR MORNINGS. </span>
					<br />
					<span className='pink'>OR NOONS. OR NIGHTS.</span>
				</h2>

				<div className='grid-container'>
					<a>
						<div className='prod extra'>
							<img alt='' src={extraExtra} />
							<p className='prod-size'>32oz &amp; 48oz</p>
							<p className='prod-hed'>EXTRA EXTRA</p>
						</div>
					</a>

					<a>
						<div className='prod vanilla'>
							<img alt='' src={vanilla} />
							<p className='prod-size'>32oz</p>
							<p className='prod-hed'>VANILLA <br />EXTRA EXTRA</p>
						</div>
					</a>

					<a>
						<div className='prod caramel'>
							<img alt='' src={caramel} />
							<p className='prod-size'>32oz</p>
							<p className='prod-hed'>CARAMEL <br />EXTRA EXTRA</p>
						</div>
					</a>

					<a>
						<div className='prod original'>
							<img alt='' src={original} />
							<p className='prod-size'>32oz</p>
							<p className='prod-hed'>ORIGINAL</p>
						</div>
					</a>
				
				</div>
			</div>
			<div className='flavors-menu-cream-border' />
		</>
	)
}

export default FlavorsMenu;