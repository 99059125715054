import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import FlavorsMenu from "./flavorsMenu";
import styles from "./header.module.scss";

import dunkinLogo from "../assets/images/icons-logos/Dunkin-logo.svg";
import iconArrowOver from "../assets/images/icons-logos/icon-arrow-over.svg";

function handleFlavorsClick(event) {
  if (window.innerWidth < 480) {
    document.querySelector('#flavors-menu').classList.toggle('mobile-show');
    return;
  }
  
  toggleMenu(event);
}

function toggleMenu(event) {
  event.preventDefault();

  document
    .querySelector(`.${styles['menu-toggle-button']}`)
    .classList.toggle(styles['x']);
  document.querySelector('header').classList.toggle(styles['menu-open']);
  document.querySelector('body').classList.toggle(styles['no-scroll']);
  document.querySelector('#header-logo').classList.toggle(styles['hide-header-logo']);
  
  // Flavors Menu
  document.querySelector('#flavors-menu').classList.toggle('desktop-show');
  document.querySelector('#flavors-menu').classList.remove('mobile-show');
}

const Header = ({ hedClassName }) => (
  <header className={styles[hedClassName]}>
    <div className={styles['hed-left']}>
      <Link to="/">
        <img
          alt="Dunkin' Logo"
          className={styles['dunkin-logo']}
          id="header-logo"
          src={dunkinLogo}
        />
      </Link>

      <button className={styles['menu-toggle-button']} onClick={toggleMenu}>
        <div className={styles['container']}>
          <div className={styles['bar1']}></div>
          <div className={styles['bar2']}></div>
          <div className={styles['bar3']}></div>
        </div>
      </button>
    </div>

    <FlavorsMenu />

    <nav className={styles['hed-right']}>
      <button className={styles['hed-flavors']} onClick={handleFlavorsClick}>
        FLAVORS
        <img alt="" className={styles['flavors-arrow']} src={iconArrowOver} />
      </button>

      <Link className={styles['hed-recipes']} to='/recipes/recipe-example'>RECIPES</Link>

      <a className={styles['hed-about']}>ABOUT</a>

      <a className={styles['hed-faq']}>FAQS</a>

      <a className={styles['hed-contact']}>CONTACT</a>
      
      <Link
        className={styles['where-to-buy']}
        to="/where-to-buy"
      >
        WHERE TO BUY
      </Link>
    </nav>


  </header>
)

Header.propTypes = {
  hedClassName: PropTypes.string,
}

Header.defaultProps = {
  hedClassName: `orange`,
}

export default Header
