import React from "react"
import { Link } from "gatsby"

import styles from "./footer.module.scss";

const Footer = () => {
	return (
		<footer>
		  <div className={styles['footer-links']}>
		    <svg id="Layer_1" className={styles['dunk-logo']} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 38">
		    	<defs>
		    		{/* <style>
		    			.cls-1{fill:none;stroke:#fff;stroke-linecap:round;}
		    			.cls-2{fill:#fff;}.cls-3{isolation:isolate;}
	    			</style> */}
    			</defs>
  				<title>Dunkin-logo</title>
  				<line id="Line_26" data-name="Line 26" className="cls-1" style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round' }} x1="165" y1="0.5" x2="165" y2="37.5"/>
  				<g id="Group_1175" data-name="Group 1175">
  				<g id="Group_1173" data-name="Group 1173">
  				<g id="Group_1172" data-name="Group 1172">
  				<g id="Group_1171" data-name="Group 1171">
  				<path id="Path_503" data-name="Path 503" className="cls-2" style={{fill: '#fff'}} d="M1.63,27.64V11.54A4.61,4.61,0,0,1,6.09,6.79h6c7.5,0,13.5,4.94,13.5,12.8s-6,12.8-13.5,12.8H6.39a4.62,4.62,0,0,1-4.76-4.47h0Zm10.68-3.43a4.61,4.61,0,1,0,.3-9.22H10.12v9.22Z" transform="translate(-1.63 -0.77)"/>
  				<path id="Path_504" data-name="Path 504" className="cls-2" style={{fill: '#fff'}} d="M27.75,21.94V10.6a4.25,4.25,0,0,1,8.49-.15V21.64a2.71,2.71,0,1,0,5.41.38v-.1a1.31,1.31,0,0,0,0-.28v-11a4.25,4.25,0,0,1,8.49-.17h0V21.94c0,6.33-4.47,11-11.2,11S27.75,28.27,27.75,21.94Z" transform="translate(-1.63 -0.77)"/>
  				<path id="Path_505" data-name="Path 505" className="cls-2" style={{fill: '#fff'}} d="M53.07,28.12V10.27a3.92,3.92,0,0,1,3.87-4h.23A4.64,4.64,0,0,1,61.12,8.4L66,15a40.52,40.52,0,0,1,2.63,3.87V11c0-2.79,1.46-4.76,4.24-4.76s4.24,2,4.24,4.76V28.92a3.91,3.91,0,0,1-3.85,4h-.2a4.7,4.7,0,0,1-4-2.12l-5.2-6.58c-.76-1-1.64-2.31-2.34-3.4v7.31c0,2.82-1.46,4.8-4.24,4.8S53.07,30.94,53.07,28.12Z" transform="translate(-1.63 -0.77)"/>
  				<path id="Path_506" data-name="Path 506" className="cls-2" style={{fill: '#fff'}} d="M93.74,31l-3.36-4.1c-.8-1-1.13-1.39-1.83-2.34v4a4.24,4.24,0,1,1-8.48.13V10.59a4.24,4.24,0,0,1,8.48-.13v4c.66-1,1-1.42,1.83-2.45l2.93-3.73c3.87-4.94,10.75.84,6.58,5.71l-4.68,5.49,5.05,5.7C104.24,29.69,97.81,36,93.74,31Z" transform="translate(-1.63 -0.77)"/>
  				<path id="Path_507" data-name="Path 507" className="cls-2" style={{fill: '#fff'}} d="M104.17,28.6v-18a4.24,4.24,0,1,1,8.48-.13V28.6a4.24,4.24,0,0,1-8.48.13Z" transform="translate(-1.63 -0.77)"/>
  				<path id="Path_508" data-name="Path 508" className="cls-2" style={{fill: '#fff'}} d="M115.58,28.12V10.27a3.94,3.94,0,0,1,3.88-4h.22a4.65,4.65,0,0,1,4,2.12l4.9,6.59a40.52,40.52,0,0,1,2.63,3.87V11c0-2.79,1.46-4.76,4.24-4.76s4.24,2,4.24,4.76V28.92a3.91,3.91,0,0,1-3.85,4h-.2a4.7,4.7,0,0,1-4-2.12l-5.19-6.58c-.77-1-1.65-2.3-2.34-3.4v7.31c0,2.82-1.47,4.8-4.25,4.8S115.58,30.94,115.58,28.12Z" transform="translate(-1.63 -0.77)"/>
  				</g></g>
  				<path id="Path_509" data-name="Path 509" className="cls-2" style={{fill: '#fff'}} d="M146.76,6.33a3.77,3.77,0,0,1,3.32,3.05,9.41,9.41,0,0,1-.52,3.9,7.61,7.61,0,0,1-1.85,3.78,3.06,3.06,0,0,1-2.46.73h0A3.1,3.1,0,0,1,143,16.44a7.61,7.61,0,0,1-.8-4.14,9.57,9.57,0,0,1,.51-3.9,3.78,3.78,0,0,1,4-2.07" transform="translate(-1.63 -0.77)"/>
  				</g><g id="Group_1174" data-name="Group 1174">
  				<path id="Path_510" data-name="Path 510" className="cls-2" style={{fill: '#fff'}} d="M144.41,31.05a1.66,1.66,0,0,1-1.6,1.7,1.65,1.65,0,1,1-.1-3.3h0A1.63,1.63,0,0,1,144.41,31.05Zm-.49,0a1.17,1.17,0,1,0-1.17,1.16h0a1.16,1.16,0,0,0,1.16-1.16Zm-.56.4a.28.28,0,0,1,.07.17.23.23,0,0,1-.22.24h0a.23.23,0,0,1-.19-.1l-.32-.43h-.06v.29a.23.23,0,0,1-.22.24h0a.23.23,0,0,1-.24-.22h0V30.48a.23.23,0,0,1,.23-.24h.49a.55.55,0,0,1,.55.56.56.56,0,0,1-.21.43Zm-.74-.51h.14a.16.16,0,0,0,.15-.16.16.16,0,0,0-.15-.16h-.14Z" transform="translate(-1.63 -0.77)"/>
  				</g></g><g id="COFFEE_CREAMERS" data-name="COFFEE CREAMERS" className="cls-3"><g className="cls-3" style={{isolation: 'isolate'}}>
  				<path className="cls-2" style={{fill: '#fff'}} d="M193.74,22.56a2,2,0,0,1,.58,1.43,2.81,2.81,0,0,1-.94,2,5.15,5.15,0,0,1-1.85,1.15,7.4,7.4,0,0,1-2.52.41,7,7,0,0,1-3.45-.86,6.31,6.31,0,0,1-2.41-2.37,6.7,6.7,0,0,1,0-6.64,6.31,6.31,0,0,1,2.41-2.37,7,7,0,0,1,3.45-.86,7.26,7.26,0,0,1,2.51.42A5.06,5.06,0,0,1,193.38,16a2.83,2.83,0,0,1,.94,2,2,2,0,0,1-.58,1.43,1.87,1.87,0,0,1-1.37.57,1.71,1.71,0,0,1-1.38-.59,2.49,2.49,0,0,0-2-.92,2.55,2.55,0,0,0-1.84.69,2.69,2.69,0,0,0,0,3.66,2.55,2.55,0,0,0,1.84.69,2.37,2.37,0,0,0,1-.23,4.24,4.24,0,0,0,.94-.69,2,2,0,0,1,1.38-.59A1.84,1.84,0,0,1,193.74,22.56Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M198.56,26.64a6.58,6.58,0,0,1-2.44-2.39,6.45,6.45,0,0,1,0-6.57,6.63,6.63,0,0,1,2.44-2.38,6.83,6.83,0,0,1,9.22,2.38,6.45,6.45,0,0,1,0,6.57,6.61,6.61,0,0,1-2.45,2.39,7,7,0,0,1-6.77,0Zm5.21-3.87a2.58,2.58,0,0,0,0-3.6,2.66,2.66,0,0,0-3.64,0,2.56,2.56,0,0,0,0,3.6,2.66,2.66,0,0,0,3.64,0Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M210.74,26.93a2.1,2.1,0,0,1-.58-1.53V16.79a2.1,2.1,0,0,1,.58-1.53,2,2,0,0,1,1.5-.59h5.48a1.77,1.77,0,0,1,1.3.51,1.89,1.89,0,0,1,0,2.58,1.77,1.77,0,0,1-1.3.51h-3.39v1.55h2.5a1.59,1.59,0,0,1,1.18.47,1.56,1.56,0,0,1,.48,1.17,1.59,1.59,0,0,1-.48,1.17,1.62,1.62,0,0,1-1.18.46h-2.5V25.4a2.1,2.1,0,0,1-.58,1.53,2.21,2.21,0,0,1-3,0Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M221.42,26.93a2.06,2.06,0,0,1-.59-1.53V16.79a2.06,2.06,0,0,1,.59-1.53,2,2,0,0,1,1.5-.59h5.47a1.76,1.76,0,0,1,1.3.51,1.86,1.86,0,0,1,0,2.58,1.76,1.76,0,0,1-1.3.51H225v1.55h2.5a1.61,1.61,0,0,1,1.65,1.64,1.59,1.59,0,0,1-1.65,1.63H225V25.4a2.1,2.1,0,0,1-.59,1.53,2.2,2.2,0,0,1-3,0Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M232.09,26.68a2.1,2.1,0,0,1-.59-1.53V16.79a2.1,2.1,0,0,1,.59-1.53,2.07,2.07,0,0,1,1.52-.59h5.45a1.78,1.78,0,0,1,1.31.51,1.89,1.89,0,0,1,0,2.58,1.78,1.78,0,0,1-1.31.51h-3.38v1h2.38a1.55,1.55,0,1,1,0,3.1h-2.38v1.33h3.38a1.78,1.78,0,0,1,1.31.51,1.89,1.89,0,0,1,0,2.58,1.78,1.78,0,0,1-1.31.51h-5.45A2.07,2.07,0,0,1,232.09,26.68Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M242.76,26.68a2.1,2.1,0,0,1-.58-1.53V16.79a2.1,2.1,0,0,1,.58-1.53,2.07,2.07,0,0,1,1.52-.59h5.46a1.8,1.8,0,1,1,0,3.6h-3.39v1h2.38a1.55,1.55,0,1,1,0,3.1h-2.38v1.33h3.39a1.8,1.8,0,1,1,0,3.6h-5.46A2.07,2.07,0,0,1,242.76,26.68Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M268.64,22.56a2,2,0,0,1,.57,1.43,2.8,2.8,0,0,1-.93,2,5.11,5.11,0,0,1-1.86,1.15,7.32,7.32,0,0,1-2.52.41,6.94,6.94,0,0,1-3.44-.86A6.4,6.4,0,0,1,258,24.29a6.77,6.77,0,0,1,0-6.64,6.4,6.4,0,0,1,2.42-2.37,6.94,6.94,0,0,1,3.44-.86,7.18,7.18,0,0,1,2.51.42A5,5,0,0,1,268.28,16a2.82,2.82,0,0,1,.93,2,2,2,0,0,1-.57,1.43,1.89,1.89,0,0,1-1.37.57,1.75,1.75,0,0,1-1.39-.59,2.47,2.47,0,0,0-2-.92,2.54,2.54,0,0,0-1.83.69,2.69,2.69,0,0,0,0,3.66,2.54,2.54,0,0,0,1.83.69,2.41,2.41,0,0,0,1.05-.23,4,4,0,0,0,.93-.69,2.07,2.07,0,0,1,1.39-.59A1.86,1.86,0,0,1,268.64,22.56Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M280.73,24.64a2.06,2.06,0,0,1,.16.79,2,2,0,0,1-2.06,2.07,1.93,1.93,0,0,1-1-.26,2.56,2.56,0,0,1-.78-.69l-2.41-3.06V25.4a2.1,2.1,0,0,1-.59,1.53,2.2,2.2,0,0,1-3,0,2.1,2.1,0,0,1-.59-1.53V16.79a2.1,2.1,0,0,1,.59-1.53,2,2,0,0,1,1.5-.59h3.49a5.21,5.21,0,0,1,2.45.56,4,4,0,0,1,1.65,1.56,4.79,4.79,0,0,1,.13,4.31A4.1,4.1,0,0,1,279,22.61l1.26,1.33A2.33,2.33,0,0,1,280.73,24.64Zm-6.08-4.3h.77a1.4,1.4,0,0,0,1-.34,1.3,1.3,0,0,0,0-1.84,1.4,1.4,0,0,0-1-.34h-.77Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M282.78,26.68a2.1,2.1,0,0,1-.59-1.53V16.79a2.1,2.1,0,0,1,.59-1.53,2,2,0,0,1,1.52-.59h5.45a1.79,1.79,0,0,1,1.31.51,1.89,1.89,0,0,1,0,2.58,1.79,1.79,0,0,1-1.31.51h-3.38v1h2.37a1.55,1.55,0,1,1,0,3.1h-2.37v1.33h3.38a1.79,1.79,0,0,1,1.31.51,1.89,1.89,0,0,1,0,2.58,1.79,1.79,0,0,1-1.31.51H284.3A2,2,0,0,1,282.78,26.68Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M304.31,25.61a1.78,1.78,0,0,1-.58,1.34,2,2,0,0,1-2.44.26,2,2,0,0,1-.69-.91l-.3-.83h-4l-.31.83a2,2,0,0,1-.67.91,1.79,1.79,0,0,1-1.06.31,1.91,1.91,0,0,1-1.38-.57,1.76,1.76,0,0,1-.6-1.34,2.19,2.19,0,0,1,.2-.86L296,16.34a4.42,4.42,0,0,1,.91-1.45,2.23,2.23,0,0,1,2.74,0,4.42,4.42,0,0,1,.92,1.45l3.54,8.41A2.09,2.09,0,0,1,304.31,25.61Zm-6-5.68-.86,2.41h1.73Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M305.38,26.91a2.37,2.37,0,0,1-.53-1.6,3.66,3.66,0,0,1,.07-.69l1.7-8a2.8,2.8,0,0,1,.87-1.63,2.4,2.4,0,0,1,1.63-.55c1.2,0,2,.74,2.43,2.23l1.2,4.27L314,16.65c.42-1.49,1.23-2.23,2.43-2.23A2.4,2.4,0,0,1,318,15a2.8,2.8,0,0,1,.87,1.63l1.69,8a3,3,0,0,1,.08.69,2.33,2.33,0,0,1-.54,1.6,1.86,1.86,0,0,1-1.5.61,1.81,1.81,0,0,1-1.38-.53,3.17,3.17,0,0,1-.71-1.65l-.37-2.26a13,13,0,0,1-.15-1.35c-.07.36-.17.77-.3,1.24l-.78,2.63a3,3,0,0,1-.81,1.45,1.93,1.93,0,0,1-1.37.47,1.91,1.91,0,0,1-1.36-.47,3,3,0,0,1-.81-1.45L309.8,23c-.13-.47-.23-.88-.3-1.24q0,.66-.15,1.35L309,25.34a3.09,3.09,0,0,1-.7,1.65,1.82,1.82,0,0,1-1.38.53A1.9,1.9,0,0,1,305.38,26.91Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M322.39,26.68a2.1,2.1,0,0,1-.58-1.53V16.79a2.1,2.1,0,0,1,.58-1.53,2.07,2.07,0,0,1,1.52-.59h5.46a1.8,1.8,0,1,1,0,3.6H326v1h2.38a1.55,1.55,0,1,1,0,3.1H326v1.33h3.39a1.8,1.8,0,1,1,0,3.6h-5.46A2.07,2.07,0,0,1,322.39,26.68Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M342.74,24.64a2.06,2.06,0,0,1,.16.79,2,2,0,0,1-2.07,2.07,1.92,1.92,0,0,1-1-.26,2.56,2.56,0,0,1-.78-.69l-2.41-3.06V25.4a2.1,2.1,0,0,1-.59,1.53,2.2,2.2,0,0,1-3,0,2.1,2.1,0,0,1-.59-1.53V16.79a2.1,2.1,0,0,1,.59-1.53,2,2,0,0,1,1.5-.59h3.49a5.21,5.21,0,0,1,2.45.56,4,4,0,0,1,1.65,1.56,4.79,4.79,0,0,1,.13,4.31A4.1,4.1,0,0,1,341,22.61l1.26,1.33A2.33,2.33,0,0,1,342.74,24.64Zm-6.08-4.3h.77a1.36,1.36,0,0,0,1-.34,1.28,1.28,0,0,0,0-1.84,1.36,1.36,0,0,0-1-.34h-.77Z" transform="translate(-1.63 -0.77)"/>
  				<path className="cls-2" style={{fill: '#fff'}} d="M346.12,27.13a4.06,4.06,0,0,1-1.81-1.23,2.57,2.57,0,0,1-.69-1.67,1.82,1.82,0,0,1,.59-1.37,1.91,1.91,0,0,1,1.36-.56,2,2,0,0,1,.93.24,2.37,2.37,0,0,1,.79.73,1.92,1.92,0,0,0,.58.53,1.29,1.29,0,0,0,.67.19.67.67,0,0,0,.48-.15.51.51,0,0,0,.17-.39A.55.55,0,0,0,349,23a2.67,2.67,0,0,0-.61-.35l-1.8-.74A3.68,3.68,0,0,1,344,18.38a3.64,3.64,0,0,1,.61-2.08,3.89,3.89,0,0,1,1.69-1.39,6,6,0,0,1,2.49-.49,6.76,6.76,0,0,1,2.26.34,4,4,0,0,1,1.69,1.13,2.06,2.06,0,0,1,.55,1.39,1.88,1.88,0,0,1-.59,1.42,1.92,1.92,0,0,1-1.39.58,2,2,0,0,1-1.6-.88,2.08,2.08,0,0,0-.51-.49,1,1,0,0,0-.57-.14.64.64,0,0,0-.42.13.41.41,0,0,0-.17.35.47.47,0,0,0,.2.39A4.28,4.28,0,0,0,349,19l1.3.5A5.59,5.59,0,0,1,352.63,21a3.65,3.65,0,0,1,.73,2.39,3.85,3.85,0,0,1-.58,2.07,4.05,4.05,0,0,1-1.7,1.48,5.7,5.7,0,0,1-2.6.55A7.17,7.17,0,0,1,346.12,27.13Z" transform="translate(-1.63 -0.77)"/>
  				</g></g>
				</svg>

				<div className={styles['footer-column']}>
					<h5>FLAVORS</h5>
					<a>Extra Extra</a>
					<a>Vanilla Extra Extra</a>
					<a>Caramel Extra Extra</a>
					<a>Original</a>
				</div>

				<div className={styles['footer-column']}>
					<h5>LEARN</h5>
					<Link to="/recipes/recipe-example">Recipes</Link>
					<a>FAQs</a>
					<a>Pressroom</a>
					<a>About Dunkin'</a>
				</div>

				<div className={styles['footer-column']}>
					<h5>BUY DUNKIN'</h5>
					<a>Where to Buy Creamers</a>
					<a>D Perks</a>
					<a>More Dunkin' Products</a>
				</div>

				<a
	        className={styles['contact-us']}
	      >
	        CONTACT US
	      </a>

    	</div>

		  <div className={styles['fine-print']}>
		  	<p>&copy; {new Date().getFullYear()} Danone North America</p>
		  	<div>
		  		<a>Terms</a>|<a>Privacy Policy</a>
	  		</div>
	  		<p>
	  			THE DUNKIN' DONUTS TRADEMARKS, LOGOS AND TRADE DRESS ARE OWNED BY DD IP HOLDER LLC. USED UNDER LICENSE. DUNKIN' DONUTS COFFEES SOLD IN GROCERY ARE MANUFACTURED AND DISTRIBUTED BY THE J.M. SMUCKER COMPANY UNDER LICENSE FROM DD IP HOLDER LLC
				</p>
			</div>
		</footer>
	)
};

export default Footer;

// <img
// 		      alt="Dunkin' Logo"
// 		      className={styles['product-image']}
// 		      src={dunkinLogo}
// 		    />